import uniqid from 'uniqid'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import './UiuxContainer.css'

const UiuxContainer = ({ uiux }) => (
  <div className='uiux'>

    {<a href={uiux.livePreview} target='_blank' rel='noopener noreferrer' >
      <img src={uiux.image} alt={uiux.alt} width='50%' className='padded'></img>
      <img src={uiux.imageb} alt={uiux.alt} width='50%' className='padded'></img>
      <img src={uiux.imagec} alt={uiux.alt} width='50%' className='padded'></img>
      <img src={uiux.imaged} alt={uiux.alt} width='50%' className='padded'></img>
    </a>}

    <a
      href={uiux.livePreview}
      aria-label='live preview'
      className='link'
      target='_blank'
      rel='noopener noreferrer'
    ><h4 className='p-b-20'>{uiux.name}</h4></a>

    <p className='uiux__description'>{uiux.description}</p>

    {/* <h1 className='no-pads'>
        <span className='semi-title no-pads'>You are on...</span><br />
        <span className='about__name truenobold'>asdasd</span>
      </h1>
      <img src={uiux.image} alt={uiux.alt} width='90%'></img> */}

    {uiux.stack && (
      <ul className='uiux__stack'>
        {uiux.stack.map((item) => (
          <li key={uniqid()} className='uiux__stack-item'>
            {item}
          </li>
        ))}
      </ul>
    )}

    {uiux.livePreview && (
      <a
        href={uiux.livePreview}
        aria-label='live preview'
        className='link link--icon'
        target='_blank'
        rel='noopener noreferrer'
      >
        <AppRegistrationIcon />
      </a>
    )}
  </div>
)

export default UiuxContainer
