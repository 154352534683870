import uniqid from 'uniqid'
import { uiux } from '../../portfolio'
import UiuxContainer from '../UiuxContainer/UiuxContainer'
import './Uiux.css'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const Uiux = () => {
  if (!uiux.length) return null

  return (
    // <section id='uiux' className='section uiux'>

    <section id='uiux' className='section'>
      <h3 className='section__title'><DragIndicatorIcon /> UI / UX</h3>

      <div className='uiux__grid'>
        {uiux.map((uiux) => (
          <UiuxContainer key={uniqid()} uiux={uiux} />
        ))}
      </div>

      {/* <div className='btn-container'>
        <a href='https://flashmike.com/web.html' aria-label='UI/UX' target='_blank' rel='noopener noreferrer'>
          <span type='button' className='btn btn--outline'>
            VIEW MORE
          </span>
        </a>
      </div> */}

    </section>
  )
}

export default Uiux
