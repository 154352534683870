const header = {
  homepage: 'https://ux.flashmike.com',
  //title: '{ ui / ux }',
}

const about = {
  name: 'ux.FlashMike',
  role: 'User Interface / Experience',
  description:
    'Interactive media, process flows, and app aesthetics.',
  resume: 'https://flashmike.com',
  social: {
    // linkedin: 'https://www.linkedin.com/in/mike-mijaro-2a35a7239/',
    // github: 'https://github.com/flashmike',
    //youtube: 'https://www.youtube.com/user/flashwebworks/videos',
  },
}

const uiux = [
  {
    name: 'My UX Design Journey',
    image: 'https://ux.flashmike.com/contents/gux.jpg',
    imageb: 'https://ux.flashmike.com/contents/gux2.jpg',
    imagec: 'https://ux.flashmike.com/contents/gux3.jpg',
    imaged: 'https://ux.flashmike.com/contents/gux4.jpg',
    alt: 'My UX Design Journey',
    description:
      'My Google UX Design Certification Journey. Training from user requirements to final product, including research and usability studies, user interviews and pain point identifications, wireframing, low- and high-fidelity mockups, information architecture, and platform responsive development.',
    stack: ['Training', 'UI', 'UX', 'Figma', 'Adobe XD'],
    livePreview: 'https://www.credly.com/badges/290f84db-c299-4899-beb1-16b6049a0b6c/public_url',
  },
  {
    name: 'iLugaw',
    image: 'https://ux.flashmike.com/contents/ilugaw.jpg',
    imageb: 'https://ux.flashmike.com/contents/ilugaw2.jpg',
    imagec: 'https://ux.flashmike.com/contents/ilugaw3.jpg',
    imaged: 'https://ux.flashmike.com/contents/ilugaw4.jpg',
    alt: 'iLugaw Mobile App',
    description:
      'Web & mobile app UX research and UI development from user pain points to high-fidelity mockups',
    stack: ['UI/UX', 'Graphics', 'Mobile App', 'Web'],
    livePreview: 'https://flashmike.github.io/react-ilugaw/',
  },
  {
    name: 'My Home',
    image: 'https://ux.flashmike.com/contents/myhome.jpg',
    imageb: 'https://ux.flashmike.com/contents/myhome2.jpg',
    imagec: 'https://ux.flashmike.com/contents/myhome3.jpg',
    imaged: 'https://ux.flashmike.com/contents/myhome4.jpg',
    alt: 'My Home',
    description:
      'Website UI development from site map, digital wireframes, high-fidelity mockups to final product',
    stack: ['UI', 'Web', 'Graphics'],
    //livePreview: 'https://app.flashmike.com/myhome/',
  },
  // {
  //   name: 'Menu Lux',
  //   image: 'https://flashmike.com/contents/web/menu.jpg',
  //   alt: 'Menu Lux',
  //   description:
  //     'Web & POS UI map and UX/interactivity',
  //   stack: ['UI/UX', 'Graphics', 'POS'],
  //   livePreview: 'https://menu.lu/fr/meilleurs-restaurants-luxembourg',
  // },
  /* {
    name: 'Clipper Oil',
    image: 'https://flashmike.com/contents/web/clipperoil.jpg',
    alt: 'Clipper Oil',
    description:
      'Interactive map UI/UX development',
    stack: ['Online Map UI', 'UX'],
    livePreview: 'https://www.clipperoil.com/',
  },
  {
    name: 'Pet Care RX',
    image: 'https://flashmike.com/contents/web/petcare.jpg',
    alt: 'Pet Care RX',
    description:
      'Online digital catalogue UI/UX development',
    stack: ['Online Catalogue UI/UX', 'Graphics'],
    livePreview: 'https://www.petcarerx.com/',
  },
  {
    name: 'Baby Ganics',
    image: 'https://flashmike.com/contents/web/babyganics.jpg',
    alt: 'Baby Ganics',
    description:
      'Online digital catalogue UI/UX development',
    stack: ['UI', 'Graphics'],
    livePreview: 'https://babyganics.com/',
  },
  {
    name: 'Bollig',
    image: 'https://flashmike.com/contents/web/bollig.jpg',
    alt: 'Bollig',
    description:
      'Online UI & UX bus ticketing system development',
    stack: ['UI/UX', 'Graphics', 'Ticketing System'],
    livePreview: 'https://bollig-mobility.lu/en/',
  }, */
]

const projects = [
  {
    name: 'My Home',
    image: 'https://flashmike.com/contents/apps/myhome.jpg',
    alt: 'My Home',
    description:
      'Real Estate website',
    stack: ['React', 'Vite', 'JSX'],
    sourceCode: '#',
    livePreview: 'https://app.flashmike.com/myhome',
  },
  {
    name: 'MaMiZiMi',
    image: 'https://flashmike.com/contents/apps/mamizimi.jpg',
    alt: 'MaMiZiMi Online Shop',
    description:
      'Amazon clone',
    stack: ['React', 'Firebase'],
    sourceCode: '#',
    livePreview: 'https://app.flashmike.com/amazon-clone/',
  },
  {
    name: 'Your Drum Shop',
    image: 'https://flashmike.com/contents/apps/your-drum-shop.jpg',
    alt: 'Your Drum Shop',
    description:
      'eCommerce product selector',
    stack: ['React', 'Redux', 'MUI'],
    sourceCode: '#',
    livePreview: 'https://flashmike.github.io/your-drum-shop/',
  },
  {
    name: 'iLugaw',
    image: 'https://flashmike.com/contents/apps/ilugaw.jpg',
    alt: 'iLugaw',
    description:
      'Online web and mobile app',
    stack: ['React', 'UI/UX', 'Android'],
    sourceCode: 'https://github.com/flashmike/react-ilugaw',
    livePreview: 'https://flashmike.github.io/react-ilugaw/',
  },
  {
    name: 'Booking System',
    //image: './assets/apps/booksys.jpg',
    image: 'https://flashmike.com/contents/apps/booksys.jpg',
    alt: 'Booking System',
    description:
      'Online Booking System',
    stack: ['React', 'Bootstrap', 'UI/UX'],
    sourceCode: '#',
    livePreview: 'https://flashmike.github.io/booking-v2/',
  },
  {
    name: 'One Nature',
    image: 'https://flashmike.com/contents/apps/onenature.jpg',
    alt: 'One Nature',
    description:
      'One Nature in React',
    stack: ['React', 'Vite', 'JSX'],
    sourceCode: '#',
    livePreview: 'https://app.flashmike.com/onenature',
  },
  {
    name: 'My Reactified CV',
    image: 'https://flashmike.com/contents/apps/mmcv.jpg',
    alt: 'My Reactified CV',
    description:
      'Reactified CV',
    stack: ['React', 'Fontawesome'],
    sourceCode: '#',
    livePreview: 'https://app.flashmike.com/cv',
  },
  {
    name: 'Triconstruct',
    image: 'https://flashmike.com/contents/apps/triconstruct.jpg',
    alt: 'Triconstruct',
    description:
      'Triconstruct in React',
    stack: ['React', 'JS'],
    sourceCode: '#',
    livePreview: 'https://triconstruct.com',
  },
]

const web = [
  {
    name: 'Kultura Trip',
    image: 'https://flashmike.com/contents/web/kultura_trip.jpg',
    alt: 'Kultura Trip',
    description:
      'e-Commerce shop - [currently building...]',
    stack: ['HTML5', 'Bootstrap'],
    livePreview: 'https://gigaflapp.com/KulturaTrip/',
  },
  {
    name: 'One Nature',
    image: 'https://flashmike.com/contents/apps/onenature.jpg',
    alt: 'One Nature',
    description:
      'One Nature in React',
    stack: ['React', 'Vite', 'JSX'],
    sourceCode: '#',
    livePreview: 'https://app.flashmike.com/onenature',
  },
  {
    name: 'Triconstruct',
    image: 'https://flashmike.com/contents/apps/triconstruct.jpg',
    alt: 'Triconstruct',
    description:
      'One Nature in React',
    stack: ['React', 'JS'],
    sourceCode: '#',
    livePreview: 'https://triconstruct.com',
  },
  {
    name: 'IKAD Engineering',
    image: 'https://flashmike.com/contents/web/ikad.jpg',
    alt: 'IKAD Engineering',
    stack: ['HTML5', 'Bootstrap', 'PHP'],
    livePreview: 'https://ikad.com.au/',
  },
  {
    name: 'Harvest Tours',
    image: 'https://flashmike.com/contents/web/harvesttours.jpg',
    alt: 'Harvest Tours',
    stack: ['WIX'],
    livePreview: 'https://www.harvesttours.com.au/',
  },
  {
    name: 'LD Records',
    image: 'https://flashmike.com/contents/web/ldr.jpg',
    alt: 'Lonesome Day Records',
    stack: ['Wordpress'],
    livePreview: 'https://www.lonesomeday.com/',
  },
]



const graphic = [
  {
    name: 'Logo',
    image: 'https://flashmike.com/contents/graphics/logo.jpg',
    alt: 'Logo',
    livePreview: 'https://flashmike.com/contents/graphics/logos/logo_eod.png',
  },
  {
    name: 'Header',
    image: 'https://flashmike.com/contents/graphics/header.jpg',
    alt: 'Header',
    livePreview: 'https://flashmike.com/graphics.html',
  },
  {
    name: 'Banner',
    image: 'https://flashmike.com/contents/graphics/banner.gif',
    alt: '300x250 Banner',
    livePreview: 'https://flashmike.com/contents/graphics/banners/300x250/6pack_300x250a.gif',
  },
  {
    name: 'Branding',
    image: 'https://flashmike.com/contents/graphics/branding.jpg',
    alt: 'Booking System',
    livePreview: 'https://flashmike.com/contents/graphics/branding/brand2_2bros.jpg',
  },
  {
    name: 'Layout',
    image: 'https://flashmike.com/contents/graphics/layout.jpg',
    alt: '300x250 Banner',
    livePreview: 'https://flashmike.com/graphics.html',
  },
  {
    name: 'Print',
    image: 'https://flashmike.com/contents/graphics/print.jpg',
    alt: 'Print',
    livePreview: 'https://flashmike.com/graphics.html',
  },
]

const skills = [
  // 'HTML',
  // 'CSS',
  // 'JavaScript',
  // 'TypeScript',
  // 'React',
  // 'Redux',
  // 'SASS',
  // 'Material UI',
  // 'Git',
  // 'CI/CD',
  // 'Jest',
  // 'Enzyme',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'mike@flashmike.com',
}

export { header, about, projects, web, uiux, graphic, skills, contact }
